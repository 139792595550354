<template>
  <v-main>
    <v-container fluid>
      <v-row
        justify="center"
        style="min-height: 100vh;"
        :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 5vh;'"
      >
        <v-col cols="12" md="5" lg="4" v-if="!success">
          <v-row justify="center">
            <v-col
              cols="11"
              md="10"
              class="text-center pa-0"
              align-self="center"
              v-if="template.var.logo_only"
            >
              <v-img
                v-if="!template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo"
                width="75%"
                class="mx-auto"
              ></v-img>
              <v-img
                v-if="template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo_light"
                width="75%"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center pa-0"
              align-self="center"
              v-if="!template.var.logo_only"
            >
              <v-img
                class="img-fluid"
                :src="'/img/' + template.var.logo_light"
              ></v-img>
            </v-col>
            <v-col cols="10" class="text-center mt-5">
              <h2 :style="`color: ${template.colors.inline_primary_text}`">
                PASSWORT VERGESSEN
              </h2>
              <p :style="`color: ${template.colors.inline_background_text}`">
                Bitte gebe Deine E-Mail-Adresse ein, um dein Passwort
                zurückzusetzen.
              </p>
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="form.email"
                filled
                rounded
                dark
                label="E-Mail"
              ></v-text-field>
              <v-btn
                block
                rounded
                dark
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                :loading="loader"
                @click="submit()"
              >
                <v-icon class="mr-2">mdi-account-lock</v-icon>
                Passwort zurücksetzen
              </v-btn>
              <v-btn
                block
                dark
                outlined
                rounded
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                class="my-4"
                :disabled="loader"
                to="/login"
              >
                Zurück zum Login
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" lg="3" v-if="success">
          <v-row justify="center">
            <v-col
              cols="11"
              md="10"
              class="text-center pa-0"
              align-self="center"
              v-if="template.var.logo_only"
            >
              <v-img
                v-if="!template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo"
                width="75%"
                class="mx-auto"
              ></v-img>
              <v-img
                v-if="template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo_light"
                width="75%"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center pa-0"
              align-self="center"
              v-if="!template.var.logo_only"
            >
              <v-img
                class="img-fluid"
                :src="'/img/' + template.var.logo_light"
              ></v-img>
            </v-col>
            <v-col cols="10" class="text-center">
              <h1 class="white--text">PASSWORT ZURÜCKGESETZT</h1>
              <p class="white--text">
                Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen deines
                Passworts an
                <span class="font-weight-bold">{{ form.email }}</span>
                geschickt.
              </p>
              <v-btn
                block
                dark
                outlined
                rounded
                large
                class="my-4"
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                to="/login"
              >
                Zurück zum Login
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :color="template.colors.primary"
      :style="`color: ${template.colors.inline_primary_text};`"
      :dark="!template.light"
      bottom
      v-model="error"
    >
      {{ errortext }}
    </v-snackbar>
  </v-main>
</template>

<script>
//import firebase from '../../firebaseConfig'
import store from '../../store'
import router from '../../routes'
import logout from '../../auth/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      show: false,
      form: {
        email: '',
        password: '',
      },
      success: false,
      loader: false,
      error: false,
      errortext: '',
    }
  },
  computed: {
    ...mapGetters({
      template: 'template',
    }),
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.isAnonymous) {
          logout.logout()
        } else {
          router.push('/start')
        }
      }
    })
  },
  methods: {
    submit() {
      if (this.form.email) {
        this.loader = true
        this.error = null
        var mail = this.form.email.replace(' ', '')
        var functions = firebase.app().functions('europe-west3')
        var resetPW = functions.httpsCallable('PasswortReset')
        resetPW({
          verband: this.template.var.verband,
          email: mail,
          domain: this.template.var.app_url,
        })
          .then((result) => {
            if (result.data.success) {
              this.success = true
            } else {
              this.error = true
              this.errortext = result.data.error
            }
            this.loader = false
          })
          .catch((error) => {
            this.creating = false
            this.error = true
            this.errortext = error
            this.loader = false
          })
      }
    },
  },
}
</script>
